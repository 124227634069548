import { useCallback } from "react";
import algoLogo from "./algo-logo.svg";
import "./App.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const App = () => {
  const particlesInit = useCallback(async (engine) => loadFull(engine), []);
  return (
    <div className="App fadeIn slide-up ">
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          background: {
            color: {
              value: "#000",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 2,
              },
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            collisions: {
              enable: false,
            },
            move: {
              enable: true,
              random: true,
              speed: 3,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 16,
            },
            opacity: {
              value: 0.4,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 0.5, max: 4 },
            },
          },
          detectRetina: true,
        }}
      />
      <img src={algoLogo} className="App-logo" alt="logo" />
      <div className="email-text">hello@algotechnologies.in</div>
    </div>
  );
};

export default App;
